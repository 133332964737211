import Vue from 'vue'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'

import '../cart-item/cart-item.vue'
import '../quantity-selector/quantity-selector.vue'
import '../shipping-calculator/shipping-calculator.vue'
import '../picture/picture.vue'
import '../recommended-variants/recommended-variants.vue'
import '../cart-gift-message/cart-gift-message.vue'
import { decode, mapCartItemNodeToGa4Item, formatPrice } from '@/lib/util'
import cart from '@/lib/cart'
import state from '@/lib/appState'
import { trigger } from '@/lib/utils'

Vue.component('cart-grid', {
  props: ['giftProduct', 'goal', 'isMiniCart', 'cartFreeGiftMessage', 'gwpProductNc', 'totalSpent', 'enableGwpProductForNc'],
  data () {
    return {
      state
    }
  },
  computed: {
    ...mapGetters([
      'subtotal',
      'giftMessageEdited'
    ]),
    ...mapState([
      'app',
      'loading',
      'isBootstrapped',
      'isMobile',
      'removedItem'
    ]),
    isEmpty () {
      return this.cart.items.length === 0
    },
    cart () {
      return this.app.cart
    },
    cartItemIds () {
      return this.cart.items.map(item => {
        return item.product_id
      })
    },
    giftProductDefaultData () {
      if (!this.giftProduct) {
        return []
      }
      const giftProductData = JSON.parse(decode(this.giftProduct))
      const giftProduct = giftProductData.length ? giftProductData.filter(item => item.available) : null
      return giftProduct.length ? giftProduct[0] : null
    },
    goalReached () {
      let totalPrice = this.cart.total_price

      this.cart.items.filter(item => item.properties._is_free_gift_update === 'true').forEach(item => {
        totalPrice -= item.final_price
      })

      return (this.goal <= totalPrice)
    }
  },
  watch: {
    'removedItem' (item) {
      if (item && Object.keys(item).length > 0) {
        this.updateRemovedItemStatus(item)
      }
    },
    'goalReached' (value) {
      if (value) {
        if (this.giftProductDefaultData && Object.keys(this.giftProductDefaultData).length > 0) {
          this.addGiftCart(this.giftProductDefaultData, 'cart', 'cart', this.cartFreeGiftMessage)
        }
      } else {
        const freeGiftItem = this.cart.items.find(item => item.properties._is_free_gift_update === 'true')
        if (freeGiftItem) {
          this.$store.dispatch('removeItems', [{
            id: freeGiftItem.key,
            quantity: 0
          }])
        }
      }
    }
  },
  methods: {
    ...mapMutations(['bootstrap']),
    ...mapMutations(['toggleMiniCart']),
    ...mapActions([
      'getCart',
      'saveGiftMessage'
    ]),
    removeAllGwp() {
      const gwpItems = this.cart.items.filter(item => item.properties?._gwp_nc === '1' || item.properties?._gift_for_purchase)
      if (gwpItems && gwpItems.length > 0) {
        const options = []
        gwpItems.map(gwpItem => {
          options.push({
            id: gwpItem.key,
            quantity: 0
          })
        })
        this.$store.dispatch('removeItems', options)
      }
    },
    removeGwpCheckout() {
      const gwpItems = this.cart.items.filter(item => item.properties?._gwp_nc === '1')
      if (gwpItems && gwpItems.length > 0) {
        const options = []
        gwpItems.map(gwpItem => {
          options.push({
            id: gwpItem.key,
            quantity: 0
          })
        })
        this.$store.dispatch('removeItems', options)
        window.location.href = '/checkout'
      }
    },
    addGiftCart(item, owner, ownerId, message) {
      cart.add([{
        id: item.firstAvailableVariant.id,
        quantity: 1,
        properties: {
          _is_free_gift_update: 'true',
          _free_gift_owner: owner,
          _free_gift_owner_id: ownerId,
          _free_gift_message: message,
        }
      }]).then(() => {
        cart.get().then((data) => {
          state.cart = data
          trigger('mini-cart-open', document.body, data)
          trigger('update-gwp', document.body)
        })
      })
    },
    updateRemovedItemStatus (item) {
      const removedItemStatus = this.$refs.removedItemStatus
      if (!removedItemStatus) {
        return
      }

      removedItemStatus.textContent = `Removed (${item.quantity}) ${item.title} from your cart.`
      removedItemStatus.setAttribute('aria-hidden', false)

      setTimeout(() => {
        removedItemStatus.setAttribute('aria-hidden', true)
      }, 1000)
    },
    async handleSubmit (e) {
      e.preventDefault()

      if (this.giftMessageEdited) {
        await this.saveGiftMessage()
      }

      if (window.reChargeProcessCart && this.cart.items && this.cart.items.some(item => item.properties && item.properties.subscription_id)) {
        window.reChargeProcessCart()
      } else {
        window.location = '/checkout'
      }
    },
    createItemGroup (item, property) {
      if (!item.properties || !item.properties.hasOwnProperty(property)) {
        return []
      }

      const bundleId = item.properties[property]

      return this.cart.items.filter(item => (
        item.properties &&
        item.properties[property] === bundleId
      ))
    },
    itemAddBundle (item) {
      return this.createItemGroup(item, '_timestamp')
    },
    itemGroupEmbellishment (item) {
      return this.createItemGroup(item, '_groupID')
    },
    pushCartViewGtm() {
      /* eslint-disable camelcase */
      const ga4Items = this.cart.items.map((item, index) => {
        const { _item_list_id, _item_list_name, _collection } = item.properties
        if (_collection) {
          item['collection'] = _collection.split('|')
        }

        return mapCartItemNodeToGa4Item(
          item,
          item.quantity,
          index,
          _item_list_id || (this.isMiniCart ? 'mini_cart' : 'cart'),
          _item_list_name || (this.isMiniCart ? 'Mini Cart' : ' Cart')
        )
      })
      /* eslint-enable */

      if (window.dataLayer) {
        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
          event: 'view_cart',
          ecommerce: {
            currency: 'USD',
            value: formatPrice(this.cart.total_price),
            items: ga4Items,
          },
        })
      }
    },
  },
  mounted () {
    this.bootstrap()
    if (!this.isMiniCart) {
      this.pushCartViewGtm()
    }
  }
})
